.createRecordContainerOuter{
    width: 100%;
    padding: var(--spacing-20px);
    height: calc(100% - 40px);
    overflow: auto;
}
.createRecordContainer{
    width: 1000px;
    background: var(--white);
    margin: auto;
    height: 100%;
    padding: var(--spacing-20px);
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-20px);
}
.form2Col {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: var(--spacing-12px);
    z-index: 6;
}
.createRecordContainer .formGroup{
    width: 49%;
}

.createRecordContainer .dropdownBox{
    width: 49%;
}
.createRecordContainer i{
    cursor: pointer;
    background: var(--grey-2);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.formFooter{
    display: flex;
    justify-content: flex-end;
}
.editorBox {
    height: 420px;
    overflow: auto;
    border-radius: 6px;
    width: 100%;
    border: 1px solid var(--grey-3);
}

@media only screen and (max-width: 540px){
    .createRecordContainer{
        width: 100%;
        padding: 10px;
    }
    .createRecordContainer .formGroup {
        width: 100%;
        gap: 5px;
    }

}