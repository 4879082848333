.breadcrumbsOuter{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-12px);
}

.breadCrumb{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-12px);
}

.breadCrumb .text{
    font-family: 'ns-semibold';
}

.listDataTable{
    width: 800px;
}

.listDataTable tr:nth-child(even) td{
    background: var(--grey-1);
}

@media only screen and (max-width: 540px){
    .listDataTable{
        width: 100%;
    }
    .breadCrumb .text{
        font-size: 12px;
        width: max-content;
    }
    
    .breadCrumb{
        gap: var(--spacing-4px);
    }
    .breadcrumbsOuter{
        gap: var(--spacing-4px);
    }
    .breadCrumb img{
        width: 15px;
    }
}