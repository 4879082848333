.dropdownBox{
    width: 100%;
    min-width: 150px;
    position: relative;
    gap: 5px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}
.selectedOption{
    position: relative;
    width: 100%;
    font-size: 14px;
    padding: 6px 8px;
    line-height: 120%;
    border-radius: 8px;
    color: var(--grey-5);
    border: var(--border-4);
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.formLabel{
    color: var(--black-4);
    font-size: 14px;
    line-height: 20px;
    font-family: 'ns-semibold';
}
.dropdownBox.active .dropdownList{
    visibility: visible;
    opacity: 1;
}
.dropdownBox.active .selectedOption{
    border-color: var(--primary-6);
    border-radius: 8px 8px 0 0;
    border-bottom-color: transparent;
}
.dropdownList{
    z-index: 1;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    list-style: none;
    border: var(--border-3);
    background: var(--white);
    border-radius: 0 0 4px 4px;
    max-height: 165px;
    overflow: auto;
}
.dropdownListItem{
    cursor: pointer;
    transition: 0.3s;
    padding: 8px 10px;
    color: var(--black-3);
    border-bottom: var(--border-1);
}
.dropdownListItem:hover{
    background: var(--primary-1);
}
.errorMsg{
    font-weight: 400px;
    font-size: 12px;
    line-height: 120%;
    color: var(--danger-6);
}
.formGroup{
    gap: 10px;
    width: 100%;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: flex-start;
}
.dropdownBox.error .selectedOption{
    border-color: var(--danger-6);
}
.dropdownBox.disabled .selectedOption{
    color: var(--grey-5);
    background: var(--grey-3);
    border-color: var(--grey-3);
    cursor: default;
    pointer-events: none;
}
.dropdownBox.disabled .selectedOption img{
    filter: contrast(0);
}

.checkboxGrp input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .checkboxGrp label {
    position: relative;
    cursor: pointer;
    color: var(--black-3);
  }
  
  .checkboxGrp label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: var(--border-3);
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    width: 16px;
    height: 16px;
    border-width: 2px;
    border-radius: 3px;
  }
  
  .checkboxGrp input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 7px;
    width: 4px;
    height: 9px;
    border:var(--border-3);
    border-width: 0 2px 2px 0;
    border-radius: 1px 0 0 1px;
    transform: rotate(45deg);
  }