.appOuter {
    height: 100%;
    width: 100%;
    overflow: auto;
    background: var(--grey-2);
}
@media only screen and (max-width: 540px){
    .loginContainerLeft{
        display: none;
    }
    .loginContainerRight{
        width: 100%;
        padding: var(--spacing-20px);
    }
    .loginFormContainer{
        width: 100%;
    }
    .welcomeSubTitle{
        width: 100%;
    }
}