.popupOuter{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    padding: 20px;
    z-index: 10;
    background:  rgba(23, 23, 23, 0.4);
    backdrop-filter: blur(5px);
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
    overflow: auto;
}

.popupContainer{
    position: relative;
    border-radius: 10px;
    width: 700px;
    margin: auto;
    height: 700px;
    background: var(--white);
}
.popupOuter.active{
    visibility: visible;
    opacity: 1;
}
.popupHeader{
    display: flex;
    align-items:  center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 16px;
    background: var(--black-5);
}

.popupTitle{
    font-family: 'ns-semibold';
    font-size: 16px;
    line-height: 22px;
    color: var(--white);
}
.confirmationPopupContainer{
    width: 420px;
    height: max-content;
    background: var(--white);
    border-radius: 6px;

}
.popupBody{
    padding: 20px 16px;
    width: 100%;
}