
.listName{
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-12px);
}

.listName .primaryText{
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--primary-5);
}

.successTag{
    background: var(--success-1);
    font-size: 12px;
    border-radius: 6px;
    padding: 2px 10px;
    max-width: max-content;
    font-family: 'ns-semibold';
    color: var(--success-6);
}