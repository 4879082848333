.headerContainer {
  width: 100%;
  background: var(--white);
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--border);
  padding: 15px 30px;
  position: sticky;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3000;
}
.headerLeft {
  /* position: absolute; */
  left: 70px;
}
.hamburger {
  position: absolute;
  left: 20px;
  cursor: pointer;
  transition: 0.5s;
}
.hamburger.active {
  transform: rotate(180deg);
}
.main-logo {
  padding-left: 40px;
}
.user-icon {
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
}
.user-icon img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.preview-btns {
  display: flex;
  align-items: center;
  gap: 10px;
}
.preview-btns .dark-btn-outline {
  display: flex;
  align-items: center;
  font-size: 13px;
  gap: 5px;
  padding: 8px 12px;
}
.preview-btns .danger-btn {
  display: flex;
  align-items: center;
  font-size: 13px;
  gap: 5px;
  padding: 8px 12px;
}


.dropdown-menu-right{
  position: relative;
}

.profile_dropdown{
  visibility: hidden;
  opacity: 0;
  transition: 0.4s;
  position: absolute;
  width: 100px;
  right: 0;
  bottom: -58px;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.1));
}

.profile_dropdown.show{
  visibility: visible;
  opacity: 1;
  transition: 0.4s;
}

.profile_dropdown ul{
  list-style: none;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
  background: var(--white);
}

.profile_dropdown ul li{
  line-height: 13.2px;
  padding: 8px;
  border-bottom: 1px solid var(--border);
  cursor: pointer;
}

.profile_dropdown ul li:hover{
  background: var(--bg-color);
}

.profile_dropdown ul li:last-child{
  border: none;
}

.pageHeaderRight{
  display: flex;
  align-items: center;
  gap: var(--spacing-20px);
  justify-content: flex-end;
}

.logOutText{
  font-size: 14px;
  line-height: 150%;
  font-family: "ns-semibold";
  color: var(--danger-5);
  cursor: pointer;
}