.uplaodcsvContainer{
    position: relative;
    border-radius: 10px;
    width: 500px;
    margin: auto;
    height:max-content;
    background: var(--white);
    overflow: auto;
}

.uplaodcsvContainer .uploadBoxOuter{
    width: 90%;
}

.downloadBtn{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
    text-decoration: none;
    font-size: 14px;
    width: max-content;
    padding: 8px 20px;
    cursor: pointer;
    font-family: 'ns-semibold';
    border-radius: 5px;
    border: 1px solid var(--primary-4);
}

.uplaodcsvContainer .formFooter{
    gap: var(--spacing-12px);
}

.uplaodcsvContainer .popupHeader{
    background: var(--black-5);
}

.uplaodcsvContainer .popupTitle {
    color: var(--white);
}