.sendMailContainer {
    width: 800px;
    background: var(--white);
    border-radius: 10px;
    overflow: auto;
}

.sendMailContainer .popupHeader {
    background: var(--black-5);
    border: none;
}

.sendMailContainer .popupTitle {
    color: var(--white);
}

.sendMailContainer .popupBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-20px);
}

.sendMailContainer .formFooter{
    width: 100%;
    justify-content: flex-end;
}

.form2Col{
    width: 100%;
}

.form2Col .formGroup{
    width: 49%;
}