.studentListPopupContainer {
    width: 500px;
    background: var(--white);
    border-radius: 10px;
    margin: auto;
    height:max-content;
}
.studentListTableContainer {
    max-height: 550px;
    background: var(--white);
    width: 100%;
    margin: auto;
    height: 100%;
    border-radius: 5px;
    overflow: auto;
    border: 1px solid var(--grey-3);
}

.studentListTable {
    width: 100%;
    text-align: left;

}

.studentListTable thead {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.studentListTable thead th {
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.studentListTable tbody td {
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.studentListTable tbody tr:hover td {
    background: var(--grey-1);
}
.studentListTable .checkmark{
    top: -5px;
}

.popupFooter{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 30px;
}